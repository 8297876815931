import axios from 'axios'
import qs from 'qs'
import { Dialog } from 'vant';

const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJ3ZWIiLCJpc3MiOiJ6c2ktY2FzLWFkbWluIiwiZXhwIjoxNzAwNTM3OTYyLCJpYXQiOjE2MTQxMzc5NjIsImp0aSI6ImI0NTUzNDYwNTVmYTQwMmFiNjdjODY0NWE5YjcxNDMzIiwidXNlcm5hbWUiOiJmaXhlZCJ9.-LUgPPlp0yYeu1h-MR5_l-GDvJQtcwYK4bngBFgK9qY'

let axiosJson = axios.create({
    baseURL: 'https://ylfh.api.huiyipro.com',
    // baseURL: 'http://localhost:8888',
    // baseURL: process.env.VUE_APP_BASE_URL_FIRST
})
axiosJson.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest';
axiosJson.defaults.headers.get['X-Requested-With'] = 'XMLHttpRequest';
axiosJson.defaults.responseType = 'json';

axiosJson.defaults.validateStatus = function () {
    return true
};

let loading = null;
axiosJson.interceptors.request.use(function (config) {
    config.headers.Accept = 'application/json';
    return config
})

let statusInfo = {
    400: "Bad Request 请求参数有误",
    401: "Unauthorized 请求需要验证",
    402: "Payment Required",
    403: "Forbidden 拒绝响应",
    404: "Not Found 未找到资源",
    405: "Method Not Allowed 方法被禁止",
}

axiosJson.interceptors.response.use(function (response) {
    //utils.clearToast(loading);
    console.log(process.env.VUE_APP_WX_APPID,'appid2')
    let status = response.status;
    if (status === 200) {
        let result = qs.parse(response.data);
        return Promise.resolve(result)
    } else {
        console.error(response)

        let message = "请求出错"
        if(status >= 400 && status < 500){
            message = statusInfo[status]
        } else if(status >= 500){
            message = "后台服务出错"
        }
        Dialog.alert({
            title: '请求处错',
            message: status + " " + message,
        })

        return Promise.reject(response)
    }
})


let basicRequest = async function (config) {

    let result = await axiosJson({
        method: config.method || "get",
        url: config.url,
        data: config.data,
        headers: config.headers || {'Content-Type': 'application/json'}
    })

    if (config.type === 0) {
        if (result.code === 200) {
            return result.data;
        } else {
            Dialog.alert({
                title: '查询失败',
                message: result.msg,
            })
        }
    } else {
        return result
    }

   
}


let http = {
    get: async function (url, type = 0) {
        return await basicRequest({url: url, type: type, headers: { token }})
    },
    post: async function (url, data = {}, type = 0) {
        return await basicRequest({url: url, type: type, data: data, method: "post", headers: { token }});
    },
    put: async function (url, data = {}, type = 0) {
        return await basicRequest({url: url, type: type, data: data, method: "put", headers: { token }});
    },
    delete: async function (url, type = 0) {
        return await basicRequest({url: url, type: type, method: "delete", headers:{ token }})
    },
    uploadFile: async function(url, data = {}, type = 0){
        return await basicRequest({url: url, type: type, data: data, method: "post", headers: {'Content-Type': 'multipart/form-data', token}});
    },
    postParams: async function (url, data = {}, type = 0) {
        return await basicRequest({url: url, type: type, data: qs.stringify(data), method: "post", headers: {'Content-Type': 'application/x-www-form-urlencoded', token}});
    },

};

export default http